<template>
  <ModalComp
    :title-text="state.modal.title"
    title-icon-class="icon-layers2"
    :custom-class="state.modal.headerClasses"
    :loading="state.form.submitting"
    :show="state.modal.show"
    :mode="state.modal.updating ? 'update' : 'create'"
    :ok-button-text="state.modal.submitBtnText"
    @hide="handleClose"
    @submit="handleSubmit"
  >

    <form class="row">
      <div class="form-group col-12">
        <label for="categoryName" class="col-form-label">
          Category Name:
          <span class="text-danger">*</span>
        </label>
        <input
          type="text"
          class="form-control"
          id="categoryName"
          placeholder="Category name"
          v-model.trim="state.form.categoryName.value"
          autocomplete="off"
          required
        >
        <FormErrorMsg :error="state.form.categoryName.error" />
      </div>
      <div
        class="form-group col-12"
      >
        <!--suppress XmlInvalidId -->
        <label for="parent" class="col-form-label">
          Parent:
        </label>
        <FormInputHelp>Select a parent only if you want to create subcategory</FormInputHelp>
        <TreeSelect
          id="parent"
          placeholder="Select a parent category"
          v-model="state.form.parent.value"
          :options="state.form.parent.options"
          :searchable="false"
          :clearable="true"
          :disabled="updating && category.is_parent"
        />
        <FormErrorMsg :error="state.form.parent.error" />
      </div>

      <div class="form-group col-6">
        <label for="sort" class="col-form-label">
          Sort:
          <span class="text-danger">*</span>
        </label>
        <FormInputHelp>Works on descending order</FormInputHelp>
        <input
          type="number"
          class="form-control"
          id="sort"
          placeholder="Sort position (descending)"
          v-model.number="state.form.sort.value"
          autocomplete="off"
          required
        >
        <FormErrorMsg :error="state.form.sort.error" />
      </div>

      <div class="form-group col-6" >
        <label for="sort" class="col-form-label">
          Enable Category:
          <span class="text-danger">*</span>
        </label>
        <FormInputHelp>Disabling will hide the category from users</FormInputHelp>
        <div>
          <SwitchComp
            :checked="state.form.status.value"
            enable-text="Enabled"
            disable-text="Disabled"
            @change="evt=>state.form.status.value=evt"
          />
        </div>
      </div>
    </form>

  </ModalComp>
</template>

<script>
import ModalComp from '@/components/Util/ModalComp';
import FormErrorMsg from '@/components/Util/FormErrorMsg';
import FormInputHelp from '@/components/Util/FormInputHelp';
import SwitchComp from '@/components/Util/SwitchComp';
import TreeSelect from '@tkmam1x/vue3-treeselect';
import { computed, reactive, watch } from 'vue';
import { useStore } from 'vuex';
import useVuelidate from '@vuelidate/core';
import { maxLength, minLength, minValue, required } from '@vuelidate/validators';
import ErrorHelper from '@/utils/ErrorHelper';
import Toaster from '@/utils/Toaster';

export default {
  name: 'CategoryAction',
  components: { ModalComp, FormErrorMsg, FormInputHelp, SwitchComp, TreeSelect },
  emits: ['hide'],
  props: {

    category: {
      type: [Object, Boolean],
      default: false
    },

    show: {
      type: Boolean,
      default: false
    },
  },

  setup (props, { emit }) {

    const store = useStore();

    const categoryState = store.state.menu.categories;

    const state = reactive({
      modal: {
        show: false,
        title: 'Create New Category',
        submitBtnText: 'Add Category',
        updating: false,
      },
      form: {
        categoryName: {
          error: false,
          value: ''
        },
        sort: {
          error: false,
          value: 0
        },
        status: {
          error: false,
          value: true
        },
        parent: {
          error: false,
          value: null,
          options: []
        },
        submitting: false
      },
    });

    state.form.parent.options = computed(() => getNonChildCategoriesAsOption(categoryState));

    watch(() => props.show, (nv) => {
      state.modal.show = nv;
    });

    watch(() => props.category, (nv) => {

      // reset the form if updating
      if (!nv) {
        // reset form
        resetForm();

        state.modal = {
          ...state.modal,
          title: 'Create New Category',
          submitBtnText: 'Add Category',
          updating: false
        };

        return false;
      }

      // or customize modal
      state.modal = {
        ...state.modal,
        title: 'Update Category',
        submitBtnText: 'Save Changes',
        updating: true
      };

      // populate values
      state.form.categoryName.value = props.category.name;
      state.form.sort.value = props.category.sort;
      state.form.parent.value = props.category.parent_id;
      state.form.status.value = props.category.status;
    });

    // validation start
    const validationRules = {
      form: {
        categoryName: {
          value: {
            required,
            minLength: minLength(2),
            maxLength: maxLength(50),
          }
        },
        sort: {
          value: {
            minValue: minValue(0)
          }
        }
      }
    };

    const validator = useVuelidate(validationRules, state, { $autoDirty: true });

    watch(validator, () => {
      ErrorHelper.getValidationErrors(validator, (errors) => {
          state.form.categoryName.error = errors['form.categoryName.value'] || false;
          state.form.sort.error = errors['form.sort.value'] || false;
        },
        {
          'form.categoryName.value': 'Category Name'
        }
      );
    });
    // validation end

    const resetForm = () => {
      state.form.categoryName.value = '';
      state.form.sort.value = 0;
      state.form.parent.value = null;
      state.form.status.value = true;
    };

    function getNonChildCategoriesAsOption (categoryState) {

      const categories = categoryState.data.categories;

      if (!categories) return [];

      return categories
        .filter(category => category.parent_id === null)
        .map(category => ({
          id: category.id,
          label: category.name
        }));
    }

    function handleClose () {
      emit('hide', false);
    }

    async function handleSubmit () {

      if (!ErrorHelper.checkValidity(validator)) return;

      const data = {
        categoryName: state.form.categoryName.value,
        parent: state.form.parent.value,
        sort: state.form.sort.value,
        status: state.form.status.value,
      };

      state.form.submitting = true;

      try {

        if (state.modal.updating) {
          await store.dispatch('menu/updateCategory', { id: props.category.id, data });
        } else {
          await store.dispatch('menu/createCategory', data);
        }

        // close the modal
        handleClose();
        resetForm();

        Toaster.successAlt({
          title: `Category ${state.modal.updating ? 'Updated' : 'Created'}`,
          message: `'${data.categoryName}' has been ${state.modal.updating ? 'updated' : 'created'}`
        });

      } catch (e) {

        Toaster.error({
          title: 'Failed',
          message: e.message
        });

        ErrorHelper.mapServerError(e, (err, ex) => {
          state.form.categoryName.error = ex(err.categoryName);
        });

      }

      state.form.submitting = false;
    }

    return {
      state,
      handleClose,
      handleSubmit,
    };
  }
};
</script>
