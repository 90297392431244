<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header card-header-with-btn bg-light">
          <div class="card-title">Menu Categories</div>
          <button
            v-if="isAuthorized(permissions.menu_category_create)"
            class="btn btn-sm btn-primary p-2"
            @click="handleActionToggle"
          >
            <i class="icon-add"/>Add New
          </button>
        </div>
        <div class="card-body" v-loading="state.loading">

          <!--list-->
          <ItemTable
            :data="state.categories"
            :columns="state.columnMap"
            :show-actions="isAuthorized(permissionsGroup.categories)"
          >
            <template #datetime="{item}">
              <span>{{ dateFormat(item.datetime) }}</span>
            </template>

            <template #parent_id="{item}">
              <span v-html="renderParent(item)" />
            </template>

            <template #status="{item}">
              <span
                class="cursor-pointer"
                v-html="renderBadge(item.status)"
              ></span>
            </template>

            <template #action="{item}" v-if="isAuthorized(permissionsGroup.categories)">

              <EditButton
                v-if="isAuthorized(permissions.menu_category_update)"
                @click="handleAction(item, constants.LIST_ITEM_ACTION_UPDATE)"
              />

              <DeleteButton
                v-if="isAuthorized(permissions.menu_category_delete)"
                :loading="item.id === state.action.subject?.id && state.action.deleteLoading"
                @click="handleAction(item, constants.LIST_ITEM_ACTION_DELETE)"

              />

            </template>

          </ItemTable>

          <!--Add Modal-->
          <CategoryAction
            :show="state.showAction"
            :category="state.action.subject"
            @hide="handleActionToggle"
          />

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, reactive } from 'vue';
import ItemTable from '@/components/Util/ItemTable';
import { dateFormat } from '@/Mixins/appHelper';
import ButtonComp from '@/components/Util/ButtonComp';
import { useStore } from 'vuex';
import CategoryAction from '@/components/Menu/CategoryAction';
import { constants } from '@/utils';
import Toaster from '@/utils/Toaster';
import permissions, { permissionsGroup } from '@/utils/permissions';
import { fetchDataIfNotExist, renderEnabledBadge } from '@/utils/Helper';
import EditButton from '@/components/Util/ListActions/EditButton';
import DeleteButton from '@/components/Util/ListActions/DeleteButton';
import SweetAlert from '@/utils/SweetAlert';

export default {
  name: 'Categories',
  components: { DeleteButton, EditButton, ItemTable, ButtonComp, CategoryAction },
  setup () {

    const store = useStore();
    const categoryStore = store.state.menu.categories;

    const state = reactive({
      showAction: false,

      loading: false,

      categories: computed(() => categoryStore?.data?.categories) || [],
      columnMap: [
        { field: 'name', header: 'Name', sortable: true },
        { field: 'parent_id', header: 'Parent', sortable: true },
        { field: 'sort', header: 'Sort', sortable: true },
        { field: 'status', header: 'Status', sortable: true },
      ],

      action: {
        types: {
          status: false,
          edit: false,
          delete: false
        },
        subject: false,
        deleteLoading: false
      },
    });


    const getData = async () => {

      const currentData = computed(() => categoryStore?.data?.categories || []);

      if (currentData.value.length) return;

      state.loading = true;
      await store.dispatch('menu/fetchCategories');
      state.loading = false;
    }

    onMounted(() => {
      fetchDataIfNotExist(categoryStore.data, async () => {
        await getData();
      });
    });

    const renderBadge = (data) => {
      return renderEnabledBadge(data);
    };

    const renderParent = (item) => {

      if (item.is_parent) {
        return '<span class="badge table-badge badge-primary badge-pill">Parent</span>';
      }

      const found = categoryStore.data.categories.find(itm => itm?.id === item.parent_id);
      return found ? found.name : 'N/A';
    };

    const handleActionToggle = (value) => {

      // set the desired action or just toggle
      const newValue = !!value || !state.showAction;

      state.showAction = newValue;

      // if closing the action then make edit false
      if (!newValue) {
        state.action.types.edit = false;
        state.action.types.status = false;
        state.action.subject = false;
      }

    };

    const handleDelete = (item) => {

      SweetAlert.confirmError({
        text: `This will permanently Delete '${item.name}'`,
        preConfirm: async (status) => {

          if (!status) return;

          state.action.deleteLoading = true;

          try {

            await store.dispatch('menu/deleteCategory', item.id);

            Toaster.successAlt({
              title: 'Deleted',
              message: `'${item.name}' has been deleted`
            })

          } catch (e) {
            Toaster.error({
              title: 'Deletion Failed',
              message: e.message
            });
          }

          state.action.deleteLoading = false;

        }
      });

    };

    const handleAction = (item, actionType) => {

      state.action.subject = item;

      switch (actionType) {
        case constants.LIST_ITEM_ACTION_UPDATE:
          state.action.types.edit = true;
          handleActionToggle();
          break;

        case constants.LIST_ITEM_ACTION_UPDATE_STATUS:
          state.action.types.status = true;
          handleActionToggle();
          break;

        case constants.LIST_ITEM_ACTION_DELETE:
          state.action.types.delete = true;
          handleDelete(item);
          break;
      }

    };

    return {
      state,
      constants,
      handleAction,
      dateFormat,
      renderBadge,
      renderParent,
      handleActionToggle,
      permissionsGroup,
      permissions
    };
  }
};
</script>

<style>
.table-badge {
  font-size: 12px;
}
</style>
